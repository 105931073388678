import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/valedictory-lecture/1.jpg';
import blogd2 from '../../assets/images/2024/valedictory-lecture/2.jpg';
import blogd3 from '../../assets/images/2024/valedictory-lecture/3.jpg';
import blogd4 from '../../assets/images/2024/valedictory-lecture/4.jpg';
import blogd5 from '../../assets/images/2024/valedictory-lecture/5.jpg';
import blogd6 from '../../assets/images/2024/valedictory-lecture/6.jpg';
import blogd7 from '../../assets/images/2024/valedictory-lecture/7.jpg';
import blogd8 from '../../assets/images/2024/valedictory-lecture/8.jpg';
import blogd9 from '../../assets/images/2024/valedictory-lecture/9.jpg';
import blogd10 from '../../assets/images/2024/valedictory-lecture/11.jpg';
import blogd11 from '../../assets/images/2024/valedictory-lecture/10.jpg';
import blogd12 from '../../assets/images/2024/valedictory-lecture/12.jpg';
// import blogd13 from '../../assets/images/2024/valedictory-lecture/13.jpg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  //   const subs = [
  //     {
  //       SNO: '1',
  //       PRESENTERS: 'DR. A.K Toye',
  //       SESSION: 'CASE 1',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. O.D Tom- AYEGUNLE',
  //       SESSION: 'CASE 2',
  //     },
  //     {
  //       SNO: '3',
  //       PRESENTERS: 'DR. T. AYO- Olagunju',
  //       SESSION: 'Timing of initiation of Dialysis AKI',
  //     },
  //     {
  //       SNO: '4',
  //       PRESENTERS: 'Dr. D. Ezuduemoih',
  //       SESSION: 'Timing of initiation of Dialysis CKD',
  //     },
  //     {
  //       SNO: '2',
  //       PRESENTERS: 'Dr. B. T. BELLO/ Dr. R. W. BRAIMOH',
  //       SESSION: 'Overview',
  //     },
  //   ];

  //   const subRows = subs.map((element) => (
  //     <tr key={element.SNO}>
  //       <td>{element.SNO}</td>
  //       <td>{element.PRESENTERS}</td>
  //       <td>{element.SESSION}</td>
  //     </tr>
  //   ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Valedictory Lecture and Commendation Service in Honor of Late
                  Professor Samuel Afolabi Olowe
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  On Wednesday, April 2, 2025, colleagues, family, and
                  well-wishers gathered at the Old Great Hall, CMUL to honour
                  the life and legacy of Professor Samuel Afolabi Olowe; LUTH
                  former Chief Medical Director who served with distinction
                  until his retirement in 1995. It was an afternoon filled with
                  heartfelt tributes, fond memories, and a deep sense of
                  gratitude for a man whose impact on medical education and
                  neonatal care in Nigeria remains profound.
                  <br />
                  Dignitaries from across the medical community gathered to pay
                  their respects.
                  {/* <Table fontSize='md' striped>
                    <thead>
                      <tr>
                        <th>SNO</th>
                        <th>PRESENTERS</th>
                        <th>SESSION</th>
                      </tr>
                    </thead>
                    <tbody>{subRows}</tbody>
                  </Table> */}
                  <br />
                  The event commenced with a warm welcome and tribute by the
                  Head of the Department of Paediatrics, Prof. V.C. Ezeaka, who
                  painted a picture of Prof. Olowe as more than just a teacher,
                  but a mentor, a guiding light, and a pioneer whose dedication
                  to newborn care in Nigeria and beyond has left indelible
                  marks.
                  <br />
                  His biography, read by Prof. A. Oduwole, chronicled his
                  remarkable journey, highlighting his contributions to medical
                  practice, research, and mentorship.
                  <br />
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Chief Medical Director of LUTH, Prof. Wasiu Lanre Adeyemo,
                  and the Provost of the College of Medicine, Prof. Ademola
                  Ayodele Oremosu delivered moving tributes, acknowledging his
                  unwavering commitment to excellence. Representatives from the
                  West African College of Physicians (Nigeria Chapter), the
                  Paediatric Association of Nigeria (PAN), the Nigerian Society
                  of Neonatal Medicine (NISONM), and Nursing professionals also
                  spoke, each testimony reinforcing the undeniable truth; Prof.
                  Olowe was a man who touched lives, shaped careers, and
                  elevated the standards of paediatric care in Nigeria and
                  beyond.
                  <br />
                  The highlight of the ceremony was the valedictory speech by
                  Prof. M.T.C. Egri-Okwaji, who reflected on the legacy of a man
                  whose wisdom, kindness, and tireless dedication will never be
                  forgotten. As the ceremony drew to a close, there was a shared
                  sense of loss, but also of celebration of a life well-lived
                  and a legacy that will continue to inspire generations to
                  come.
                  <br />
                  Professor. Samuel Afolabi Olowe may be gone, but his influence
                  remains deeply woven into the fabric of Nigerian medicine. As
                  he departs, it is hoped that the shining legacies of this
                  uncommon and distinguished doyen of medical practice will be
                  sustained by all whose lives he impacted while he was alive.
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>

                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  She said that NSOANP had to do with providing affordable,
                  accessible and safe surgery to Nigerians that need it as part
                  of Universal Health Coverage. “This is as a result of World
                  Health Assembly Resolution in 2015 which mandated Member
                  States to include basic and Surgical Care as part of Universal
                  coverage in the country. “Federal Ministry of Health produced
                  the plan and most of the implementation takes place at the
                  State level. “That is why we started the dissemination to the
                  36 States and the Federal Capital Territory after approval was
                  given at the National Council of Health,”. She said that World
                  Health Assembly Resolution 68:15 says Surgical, Obstetrics,
                  Anesthesia and Nursing care are important components of
                  Universal Health Coverage, therefore, Surgeries are fully
                  integrated into Healthcare Services.
                </p> */}
                <p>Adieu Professor Samuel Afolabi Olowe.</p>

                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} />
                  </div>
                </div>
                <div className='row'>
                  {/* <div className='col-lg-12'>
                    <img src={blogd13} height={370} />
                  </div> */}
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Lagos University Hospital World Breastfeeding Week (WBW)  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
